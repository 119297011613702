import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import './onboarding.css';
import onboardimages from "../../assets/step3.png";
import { OnboardingContext } from '../../context/OnboardingContext';
import User from '../../service/User/User';
import { API_BASE_URL } from '../../utils/constant';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Step3 = ({ onNext, onPrevious }) => {
  const [counties, setCounties] = useState([]);
  const [cities, setCities] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const { selectedTopics, selectedCounties, updateSelectedCounties, updateSelectedCities, selectedCities } = useContext(OnboardingContext);

  const navigate = useNavigate();

  const topics = [
    { id: 1, name: "Legistative Updates" },
    { id: 2, name: "Bill Tracking" },
    // other topics...
  ];

  useEffect(() => {
    const fetchCountyData = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/county/fetchAllCounty`
        );
        const data = await response.json();
        setCounties(data.results || []);
      } catch (error) {
        console.error("Error fetching county data:", error);
      }
    };
    fetchCountyData();
  }, []);

  useEffect(() => {
    const fetchCitiesData = async () => {
      if (selectedCounties.length > 0) {
        try {
          const citiesData = await Promise.all(
            selectedCounties.map(async (county) => {
              const response = await fetch(`${API_BASE_URL}/city/fetchCitiesByCounty/${county.value}`);
              const data = await response.json();
              return data.results || [];
            })
          );
          setCities(citiesData.flat());
        } catch (error) {
          console.error("Error fetching city data:", error);
        }
      } else {
        setCities([]);
      }
    };
    fetchCitiesData();
  }, [selectedCounties]);

  const handleCountyChange = (selectedOptions) => {
    updateSelectedCounties(selectedOptions || []);
  };

  const handleCityChange = (selectedOptions) => {
    updateSelectedCities(selectedOptions || []);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const handleFinishButton = async () => {
    const preferenceCity = selectedCities.map((city) => city.value);
    const preferenceCounties = selectedCounties.map((county) => county.value);
    const topicsData = topics.filter((topic) => selectedTopics.includes(topic.id));
    const topicsInterest = topicsData.map((item) => item.name);
    const payload = {
      topics_interest: topicsInterest,
      preference_city: preferenceCity,
      preference_counties: preferenceCounties,
    };
    try {
      const result = await User.profileCompleted(payload);
      if (result.success) {
        toast.success(result.message);
        navigate("/home")
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container fluid className="main-wrap">
      <Row>
        <Col md={2} className="timeline-container">
          <div className="timeline">
            <div className="timelineItem"></div>
            <div className="timelineItem"></div>
            <div className="timelineItemActive"></div>
          </div>
        </Col>
        <Col md={7}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formCounty">
                <Form.Label>County</Form.Label>
                <Select
                  isMulti
                  options={counties.map((county) => ({
                    value: county._id,
                    label: county.name,
                  }))}
                  value={selectedCounties}
                  onChange={handleCountyChange}
                  placeholder="Select County"
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formCity">
                <Form.Label>City</Form.Label>
                <Select
                  isMulti
                  options={cities.map((city) => ({
                    value: city._id,
                    label: city.name,
                  }))}
                  value={selectedCities}
                  onChange={handleCityChange}
                  placeholder="Select City"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isDisabled={!selectedCounties.length}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formSearch">
                <Form.Label>Search</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter search term"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                {searchTerm && cities.length > 0 && (
                  <ul className="suggestions-list">
                    {cities.filter(city => city.name.toLowerCase().includes(searchTerm.toLowerCase())).map((suggestion) => (
                      <li
                        key={suggestion._id}
                        onClick={() => handleCityChange([...selectedCities, { value: suggestion._id, label: suggestion.name }])}
                        className="suggestion-item"
                      >
                        {suggestion.name}
                      </li>
                    ))}
                  </ul>
                )}
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-evenly mt-4">
            <Button variant="secondary" onClick={onPrevious}>Previous</Button>
            <Button variant='secondary' onClick={handleFinishButton}>Finish</Button>
          </div>
        </Col>
        <Col md={3}>
          <div className="imageContainer">
            <img src={onboardimages} alt="Illustration of region preferences" className="" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Step3;
