import React from 'react';
import { ColorRing } from "react-loader-spinner";
import "./style.css"

const FullScreenLoader = () => {
  return (
    <div className="loader-container">
      <ColorRing
        visible={true}
        height="50"
        width="50"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={['#2d3648', '#2d3648', '#2d3648', '#2d3648', '#2d3648']}
      />
      <h4>...Loading </h4>
     
    </div>
  );
}

export default FullScreenLoader;
