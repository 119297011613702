import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <div className='mt-5'>
        <section className='footer-section'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="footer-content">
                            <h3>© 2024 All Rights Reserved.</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      
    </div>
  )
}

export default Footer
