import { doFetch, REQUEST_METHODS } from "../fetcher";
import CHATBOT_API_ENDPOINTS from "./ChatbotAPIEndPoints";

export default {
    getChatBotMessage: (payload) => {
        return doFetch(
            `${CHATBOT_API_ENDPOINTS.GET_CHATBOT_MESSAGE}`,
            REQUEST_METHODS.POST,
            payload
        );
    },
    getAllChatHistoryData: () => {
        return doFetch(`${CHATBOT_API_ENDPOINTS.GET_ALL_CHAT_HISTORY}`, REQUEST_METHODS.GET)
    },
    getChatHistoryDataById: (id) => {
        return doFetch(`${CHATBOT_API_ENDPOINTS.GET_CHAT_HISTORY_BY_ID}/${id}`, REQUEST_METHODS.GET)
    },
    deleteChatHistoryDataById: (id) => {
        return doFetch(`${CHATBOT_API_ENDPOINTS.DELETE_CHAT_HISTORY_BY_ID}/${id}`, REQUEST_METHODS.DELETE)
    },
    deleteallchathistory:()=>{
        return doFetch(`${CHATBOT_API_ENDPOINTS.DELETE_ALL_CHAT_HISTORY}` ,REQUEST_METHODS.DELETE)
    }
}
