import { createSlice } from "@reduxjs/toolkit";
import { deletingSearchAdvanceFilter, fetchingCitiesByCounties, getAllAdvanceFilters, getallCouncilMembers, getAllSearchAdvanceFilters } from "./advanceFilterThunk";

const initialState = {
    filteredData: [],
    selectedOptions: [],
    allAdvancedFiltersData: [],
    advancedSearchFiltersData: {
        currentPage: 1,
        currentSize: 5,
        records: [],
        totalCount: 0
    },
    citiesByCounties: [],
    error: null,
    status: "idle",
    isLoading: false,
    appliedFilter: [
        {
            city: [],
            county: [],
            title: "",
            filterParameter: [],
            isSaveSearch: false,
            saveSearchInput: "",
            startDate: null,
            endDate: null,
        },
    ],
};

const advanceFilterSlice = createSlice({
    name: "advancefilters",
    initialState,
    reducers: {
        toggleOption: (state, action) => {
            const option = action.payload;
            if (state.selectedOptions.includes(option)) {
                state.selectedOptions = state.selectedOptions.filter(
                    (item) => item !== option
                );
            } else {
                state.selectedOptions.push(option);
            }
        },
        clearCitiesByCounties: (state) => {
            state.citiesByCounties = []
        },
        handleChangeAppliedFilter: (state, action) => {
            state.appliedFilter = state.appliedFilter.map((filter) => {
                return (
                    {
                        ...filter,
                        ...action.payload
                    }
                )
            })
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getallCouncilMembers.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getallCouncilMembers.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.filteredData = action.payload?.results || [];
            })
            .addCase(getallCouncilMembers.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload || "Failed to fetch council members";
            });

        builder.addCase(getAllAdvanceFilters.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
            .addCase(getAllAdvanceFilters.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.allAdvancedFiltersData = action.payload?.results || [];
            })
            .addCase(getAllAdvanceFilters.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || "Failed to save filter";
            });

        builder.addCase(getAllSearchAdvanceFilters.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
            .addCase(getAllSearchAdvanceFilters.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.advancedSearchFiltersData = action.payload?.results || [];
            })
            .addCase(getAllSearchAdvanceFilters.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || "Failed to save filter";
                state.advancedSearchFiltersData = [];
            });

        builder.addCase(deletingSearchAdvanceFilter.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
            .addCase(deletingSearchAdvanceFilter.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(deletingSearchAdvanceFilter.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || "Failed to save filter";
                state.advancedSearchFiltersData = [];
            });

        builder.addCase(fetchingCitiesByCounties.pending, (state, action) => {
            state.isLoading = true;
            state.error = null;
        }).addCase(fetchingCitiesByCounties.fulfilled, (state, action) => {
            state.isLoading = false;
            state.citiesByCounties = action.payload.results
            state.error = null;
        })
            .addCase(fetchingCitiesByCounties.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || "Failed to save filter";
                state.citiesByCounties = [];
            });
    },
});

export const { toggleOption, clearCitiesByCounties, handleChangeAppliedFilter } = advanceFilterSlice.actions;
export default advanceFilterSlice.reducer;




