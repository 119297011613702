import { initializeApp } from "firebase/app";
import {  getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const app = initializeApp({
  apiKey: "AIzaSyALwE2WI3CCSBIaw2A9dwXNaFR7_cXjo3w",
  authDomain: "legislative-tracker-104f8.firebaseapp.com",
  projectId: "legislative-tracker-104f8",
  storageBucket: "legislative-tracker-104f8.appspot.com",
  messagingSenderId: "1072522742080",
  appId: "1:1072522742080:web:87818694b8ef04a985d2a5",
  measurementId: "G-KX8K2KQCCS",
});
const auth = getAuth(app);
const db = getFirestore(app);

export {  db, auth };
