import React from 'react';
import './notification.css'; // Custom styles for notifications
import { useNotification } from '../../context/NotificationContext';
import clearfiltericon from '../../assets/notification-clear-all.png';

const Notification = ({ isVisible, handleClearAll }) => {
  const { notifications, removeNotification } = useNotification();

  return (
    isVisible && (
      <div className="notification-panel">
        <div className="notification-header">
          <span className="notification-title">Notifications</span>
          <span onClick={handleClearAll} className="clear-all-btn">
            <img src={clearfiltericon} height={16} width={16} className="clear-all" alt="Clear All" />
            Clear All
          </span>
        </div>
        <div className="notification-list">
          {notifications.map((notification) => (
            <div
              className={`notification-item ${notification.visible ? 'visible' : 'hidden'}`}
              key={notification.id}
            >
              <div className="notification-details">
                <div className="notification-title">
                  {notification.title || 'Notification'}
                  <span className="notification-timestamp">
                    {notification.timestamp || new Date().toLocaleTimeString()}
                  </span>
                </div>
                <div className="notification-message">{notification.message}</div>
              </div>
              <button className="notification-dismiss" onClick={() => removeNotification(notification.id)}>
                Dismiss
              </button>
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default Notification;
