import { Navigate, Outlet, useLocation } from "react-router-dom";

const PublicRoute = () => {
  const authData = localStorage.getItem("auth");
  const location = useLocation();

  console.log("location", location);
  if (authData) {
    return <Navigate to={`${location.state?.from || "/home"}`} replace />;
  }

  return <Outlet />;
};

export default PublicRoute;
