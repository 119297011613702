import { doFetch, REQUEST_METHODS, REQUEST_CONTENT_TYPE } from "../fetcher";
import USER_API_ENDPOINTS from "./UserAPIEndPoints";

export default {
    userRegister: (payload) => {
        return doFetch(
            `${USER_API_ENDPOINTS.REGISTER}`,
            REQUEST_METHODS.POST,
            payload
        );
    },
    userLogin: (payload) => {
        return doFetch(
            `${USER_API_ENDPOINTS.LOG_IN}`,
            REQUEST_METHODS.POST,
            payload
        );
    },
    userUpdate: (payload) => {
        return doFetch(
            `${USER_API_ENDPOINTS.USER_UPDTE}`,
            REQUEST_METHODS.PUT,
            payload
        );
    },
    userDetails: () => {
        return doFetch(
            `${USER_API_ENDPOINTS.USER_DETAILS}`,
            REQUEST_METHODS.GET
        );
    },
    changePassword: (payload) => {
        return doFetch(
            `${USER_API_ENDPOINTS.CHANGE_PASSWORD}`,
            REQUEST_METHODS.PUT,
            payload
        );
    },
    forgetPassword: (payload) => {
        return doFetch(
            `${USER_API_ENDPOINTS.FORGOT_PASSWORD}`,
            REQUEST_METHODS.POST,
            payload
        );
    },
    resetPassword: (payload) => {
        return doFetch(
            `${USER_API_ENDPOINTS.RESET_PASSWORD}`,
            REQUEST_METHODS.POST,
            payload
        );
    },
    profileCompleted: (payload) => {
        return doFetch(
            `${USER_API_ENDPOINTS.PROFILE_COMPLETED}`,
            REQUEST_METHODS.PUT,
            payload
        );
    },
    uploadProfile: (payload, config) => {
        return doFetch(
            `${USER_API_ENDPOINTS.UPLOAD_PROFILE}`,
            REQUEST_METHODS.POST,
            payload,
            {
                contentType: REQUEST_CONTENT_TYPE.MULTIPART,
                ...config,
            }
        );
    },
    summarizeContent: (payload) => {
        return doFetch(
            `${USER_API_ENDPOINTS.SUMMARIZE_CONTENT}`,
            REQUEST_METHODS.POST,
            payload
        );
    },
    getAIBotMessage: (payload) => {
        return doFetch(
            `${USER_API_ENDPOINTS.GET_AI_BOT_MESSAGE}`,
            REQUEST_METHODS.POST,
            payload
        );
    },
    deleteChatById: (payload) => {
        return doFetch(
            `${USER_API_ENDPOINTS.DELETE_CHAT_BY_ID}`,
            REQUEST_METHODS.POST,
            payload
        );
    },
};