import FullScreenLoader from "../components/fullScreenLoader/FullScreenLoader";
import { OnboardingProvider } from "../context/OnboardingContext";
import Onboarding from "../pages/onBoarding/Onboarding";
import Login from "../pages/login_register/Login";
import Notification from "../components/notifications/Notification"; // Import Notification
import { Route, Routes } from "react-router-dom";
import Error from "../pages/errorpage/Error";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./PublicRoute";
import { Suspense, lazy, useState } from "react";

const ForgetPassword = lazy(() =>
  import("../pages/forget-reset/ForgetPassword")
);
const ResetPassword = lazy(() => import("../pages/forget-reset/ResetPassword"));
const Register = lazy(() => import("../pages/login_register/Register"));
const Profile = lazy(() => import("../pages/profile/Profile"));
const Home = lazy(() => import("../pages/home/Home"));
const SearchPage = lazy(() => import("../pages/search/SearchPage"));
const Summarize = lazy(() => import("../pages/summarize/Summarize"));

const AppRoutes = () => {
    const [shownotification,setshownotification] = useState(false)
    const handlenotificationclick = ()=>{
      setshownotification(true)

      setTimeout(() => {
        setshownotification(false)
        
      }, 5000);
    }
  return (
    <>
      {/* Notification component to show global notifications */}
      <Notification/>

      <Suspense fallback={<FullScreenLoader open />}>
        <Routes>
          <Route path="/" element={<PublicRoute />}>
            <Route index element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>

          <Route path="/" element={<PrivateRoute />}>
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="profile" element={<Profile />} />
            <Route path="search" element={<SearchPage />} />
            <Route path="summarize" element={<Summarize />} />
            <Route
              path="/onboard"
              element={
                <OnboardingProvider>
                  <Onboarding />
                </OnboardingProvider>
              }
            />
          </Route>
          <Route path="/*" element={<Error />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
