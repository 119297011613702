import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../service/firebase/FirebaseService";
import { toast } from "react-toastify";
import { registerUser } from "../redux/features/user/userThunk";

export const googleLoginHandler = async (dispatch, navigate) => {
  try {
    const googleProvider = new GoogleAuthProvider();
    googleProvider.setCustomParameters({
      prompt: "select_account ",
    });

    const result = await signInWithPopup(auth, googleProvider);

    if (result?.user?.email) {
      const payload = {
        email: result.user.email,
        name: result.user.displayName,
        profile_picture: result?.user?.photoURL,
        user_type: "social"
      };

      try {
        const resultAction = await dispatch(registerUser(payload));
        let isUserAuthenticated = JSON.parse(localStorage.getItem("auth"));

        if (isUserAuthenticated?.token) {
          toast.success(`Welcome back ${isUserAuthenticated?.userDetail.name}`);
          navigate("/home");
        } else {
          toast.error(resultAction.payload?.message || "Login failed");
        }
      } catch (error) {
        console.log("errorerror", error);
        toast.error(error);
      }
    }
  } catch (error) {
    console.log(error?.message);
  }
};