import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "../features/user/userSlice";
import filterSlice from "../features/filter/filterSlice";
import dataSlice from "../features/data/dataSlice";
import advancefilterSlice from "../features/advanceFilter/advanceFilterSlice";
import chatbotSlice from "../features/chatbot/chatbotSlice";

export const rootReducer = combineReducers({
    user: userSlice,
    filter: filterSlice,
    data: dataSlice,
    advancefilter: advancefilterSlice,
    chatbot: chatbotSlice
});
