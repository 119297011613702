import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./routes";
import { NotificationProvider } from "./context/NotificationContext";
import Footer from "./components/Footer/Footer"; 

const App = () => {
  return (
    <Router>
      <div className="flex-wrapper">
        <ToastContainer />
        <div className="content">
          <NotificationProvider>
            <AppRoutes />
          </NotificationProvider>
        </div>
      </div>
        <Footer/>
    </Router>
  );
};

export default App;
