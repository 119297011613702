import React, { useContext, useState, useEffect } from 'react';
import { Row, Container, Col, Button } from 'react-bootstrap';
import './onboarding.css';
import LegistativeUpdates from "../../assets/onboarding/legistative updates.jpg";
import Billtracking from "../../assets/onboarding/bill treacking.jpg";
import ComiteeReports from "../../assets/onboarding/cometee reports.jpg";
import LegistativeCalendar from "../../assets/onboarding/legistative calendar.jpg";
import publicHearing from "../../assets/onboarding/public hearing.jpg";
import votingrecord from "../../assets/onboarding/voting records.jpg";
import policyanalysis from "../../assets/onboarding/policy analysis.jpg";
import legistativehistory from "../../assets/onboarding/legistative history.jpg";
import regulatorychanges from "../../assets/onboarding/regularity changes.jpg";
import budgetregulation from "../../assets/onboarding/budgets legistations.png";
import publiccomments from "../../assets/onboarding/public comments.jpg";
import lobbyingactivities from "../../assets/onboarding/lobbyiing activities.png";
import legistatorprofiles from "../../assets/onboarding/legistator profiles.jpg";
import amendmenttracking from "../../assets/onboarding/amendant tracking.png";
import legistativesummary from "../../assets/onboarding/legistative summary.jpg";
import fiscal from "../../assets/onboarding/fiscal.png";
import legistativenews from "../../assets/onboarding/legistative news.jpg";
import electionresults from "../../assets/onboarding/election results.png";
import legistativeglossary from "../../assets/onboarding/legistative glossy.jpg";
import judicialreviews from "../../assets/onboarding/judicial reviews.png";
import background from "../../assets/step2.png";
import { OnboardingContext } from '../../context/OnboardingContext';

const Step2 = ({ onNext, onPrevious }) => {
  const { selectedTopics, toggleTopicSelection, setSelectedTopics } = useContext(OnboardingContext);

  const topics = [
    { id: 1, name: "Legistative Updates", image: LegistativeUpdates },
    { id: 2, name: "Bill Tracking", image: Billtracking },
    { id: 3, name: "Commitee Reports", image: ComiteeReports },
    { id: 4, name: "Legistative Calendar", image: LegistativeCalendar },
    { id: 5, name: "Public Hearing", image: publicHearing },
    { id: 6, name: "Voting Record", image: votingrecord },
    { id: 7, name: "Policy Analysis", image: policyanalysis },
    { id: 8, name: "Legistative History", image: legistativehistory },
    { id: 9, name: "Regulatory Changes", image: regulatorychanges },
    { id: 10, name: "Budget Legistation", image: budgetregulation },
    { id: 11, name: "Public Comments", image: publiccomments },
    { id: 12, name: "Lobbying Activities", image: lobbyingactivities },
    { id: 13, name: "Legistator Profiles", image: legistatorprofiles },
    { id: 14, name: "Legistative Summaries", image: legistativesummary },
    { id: 15, name: "Amendment Tracking", image: amendmenttracking },
    { id: 16, name: "Fiscal Impact Statements", image: fiscal },
    { id: 17, name: "Legistative news", image: legistativenews },
    { id: 18, name: "Election Results", image: electionresults },
    { id: 19, name: "Legistative Glossary", image: legistativeglossary },
    { id: 20, name: "Judicial Reviews", image: judicialreviews }
  ];

  const [selectAll, setSelectAll] = useState(false);

  const isAnyTopicSelected = selectedTopics?.length > 0;

  useEffect(() => {
    if (selectedTopics.length === topics.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedTopics, topics.length]);

  const handleSelectAll = () => {
    if (selectAll) {
      // Unselect all
      setSelectedTopics([]);
    } else {
      // Select all
      setSelectedTopics(topics.map((topic) => topic.id));
    }
    setSelectAll(!selectAll);
  };

  return (
    <section>
      <Container fluid>
        <Row>
          <Col md={2} className="timeline-container">
            <div className="timeline">
              <div className="timelineItem"></div>
              <div className="timelineItemActive"></div>
              <div className="timelineItem"></div>
            </div>
          </Col>
          <Col md={7}>
            <h4 className={`step2-heading-intrest ${isAnyTopicSelected ? 'text-blue' : ''}`}>
              What Are Your Interests/Preference?
            </h4>
            <h2 className={`step2-topic-intrest ${isAnyTopicSelected ? 'text-blue' : ''}`}>
              Topics Interests <span className='step2-pick'> (pick at least 5/20)</span>
              <span className='input-checkbox-heading'>
                <span className='step2-checkbox-label'>
                  <label htmlFor="Selectall">Select All</label>
                </span>
                <input
                  type="checkbox"
                  name="Select All"
                  id="selectAll"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className='step-2-input-checkbox'
                />
              </span>
            </h2>
            <div style={{ maxHeight: '400px', overflowY: 'auto', paddingRight: '15px', marginBottom: '20px' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {topics.map((topic) => (
                  <div
                    className='step2-legit-img-container'
                    key={topic.id}
                    onClick={() => toggleTopicSelection(topic.id)}
                    style={{
                      border: selectedTopics?.includes(topic.id) ? '1.8px solid blue' : '1.8px solid gray',
                      color: selectedTopics?.includes(topic.id) ? 'blue' : 'black',
                      cursor: 'pointer',
                      textAlign: 'center',
                      borderRadius: '12px',
                      margin: '10px',
                      padding: '10px'
                    }}
                  >
                    <p>{topic.name}</p>
                    <img src={topic.image} className='step2-legit-img' alt={topic.name} />
                  </div>
                ))}
              </div>
            </div>
            <div className="navigation-buttons">
              <Button variant="secondary" className="mr-2" onClick={onPrevious}>Previous</Button>
              <Button variant="primary" onClick={onNext} disabled={selectedTopics?.length < 5}>Next</Button>
            </div>
          </Col>
          <Col md={3}>
            <div className='imageContainer'>
              <img className='img-banner' src={background} alt="Illustration" />
            </div>
          </Col>
        </Row>
      </Container>
    </section >

  );
};

export default Step2;
