const USER_API_ENDPOINTS = {
    REGISTER: "/auth/register",
    LOG_IN: "/auth/login",
    USER_UPDTE: "/update/user",
    USER_DETAILS: "/user/get-details",
    CHANGE_PASSWORD: "/user/changePassword",
    FORGOT_PASSWORD: "/auth/forgotPassword",
    RESET_PASSWORD: "/auth/resetPassword",
    PROFILE_COMPLETED: "/user/profileCompleted",
    UPLOAD_PROFILE: "/user/uploadProfile",
    SUMMARIZE_CONTENT: "/ai/summarize",
    GET_AI_BOT_MESSAGE: "/ai/chat",
    DELETE_CHAT_BY_ID: "/ai/chatHistory/delete"
};
export default USER_API_ENDPOINTS;
