import React from 'react';
import './error.css'; 
import error from "../../assets/error.png"

const Error = () => {
  return (
    <div className="error-page">
      <div className="error-content">
        <div className="error-image">
          <img src= {error} alt="404 Error" />
        </div>
        <h1>OOPS!</h1>
        <h2>404</h2>
        <p>The Page you're looking for isn't available.</p>
        <p>
          For more information you can contact: <a href="mailtoxyz@xyz.com">legislative@gmail.com</a>
        </p>
        <div className="error-buttons">
          <button onClick={() => window.location.href = '/'}>Go Home</button>
          <button onClick={() => window.history.back()}>Go Back</button>
        </div>
      </div>
    </div>
  );
}

export default Error;
