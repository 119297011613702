import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../redux/features/user/userThunk";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./login.css";
import { toast } from "react-toastify";
import Login_background from '../../assets/login_background.jpg'
import "react-toastify/dist/ReactToastify.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { googleLoginHandler } from "../../utils/helper";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string().required("Required"),
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((store) => store.user.user);

  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const { email, password } = values;

      try {
        const resultAction = await dispatch(loginUser({ email, password }));
        let isUserAuthenticated = await JSON.parse(
          localStorage.getItem("auth")
        );

        if (isUserAuthenticated?.token) {
          toast.success(`Welcome back ${isUserAuthenticated?.userDetail.name}`);
          navigate("/home");
        } else {
          setFieldError("email", "Invalid email or password");
          setFieldError("password", "Invalid email or password");
        }
      } catch (error) {
        setFieldError("email", "An error occurred. Please try again.");
        setFieldError("password", "An error occurred. Please try again.");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handlePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className="section login-section">
      <Container className="login-container p-0">
        <Row>
          <Col md={5} className="image-col">
            <img
              src={Login_background}
              className="login-image"
              alt="Login"
            />
          </Col>

          <Col md={7} className="form-col">
            <h2 className="text-center form-heading">Login</h2>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.email && formik.errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.password && formik.errors.password}
                  />
                  <div
                    className="position-absolute"
                    onClick={handlePasswordVisibility}
                    style={{
                      right: "30px",
                      top: "50%",
                      cursor: "pointer",
                    }}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </div>
                </div>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className="mb-3"
                style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  id="keepMeLoggedIn"
                  name="keepMeLoggedIn"
                  className="me-2"
                />
                <label
                  htmlFor="keepMeLoggedIn"
                  className="text-muted keep-me-checkbox">
                  Keep me logged in
                </label>
                <a href="/forgot-password" className="forget-pass-link">
                  Forgot Password?
                </a>
              </Form.Group>

              <button
                className="login-submit-btn"
                type="submit"
                disabled={formik.isSubmitting}>
                {formik.isSubmitting ? "Logging In..." : "Login"}
              </button>
            </Form>

            <p className="styled-heading">or</p>
            <button
              className="login-with-google"
              onClick={() => googleLoginHandler(dispatch, navigate)}>
              Login with Google
            </button>

            <p className="signup-link text-center">
              Don't have an account?{" "}
              <span>
                <a href="/register">Sign Up</a>
              </span>
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
       
      </Container>
    </div>
  );
};

export default Login;
