import React, { useContext, useState } from 'react';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { OnboardingContext } from '../../context/OnboardingContext';


const Onboarding = () => {
  const { currentStep, handleNext, handlePrevious } = useContext(OnboardingContext);
  const intro = introJs();
  intro.setOptions({
    steps: [
      {
        element: '#step1',
        intro: 'This is step 1',
      },
      {
        element: '#step2',
        intro: 'This is step 2',
      },
      {
        element: '#step3',
        intro: 'This is step 3',
      },

    ],
    showProgress: false,
    showBullets: false,
    nextLabel: '',
    prevLabel: '',
    doneLabel: '',
    exitOnEsc: false,
    exitOnOverlayClick: false,
  });

  const startTour = () => {
    intro.start().goToStep(currentStep + 1).start();
  };

  const renderStepComponent = () => {
    switch (currentStep) {
      case 0:
        return <Step1 onNext={handleNext} onPrevious={null} />;
      case 1:
        return <Step2 onNext={handleNext} onPrevious={handlePrevious} />;
      case 2:
        return <Step3 onNext={handleNext} onPrevious={handlePrevious} />;
      default:
        return <Step1 onNext={handleNext} onPrevious={null} />;
    }
  };

  return (
    <div>
      {renderStepComponent()}
    </div>
  );
};

export default Onboarding;
