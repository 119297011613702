import React from 'react';
import onboardimages from "../../assets/step1.jpg";
import { Container, Row, Col, Button } from 'react-bootstrap';
import './onboarding.css'; 

const Step1 = ({ onNext }) => {
  return (
    <Container fluid className="container-onboarding">
      <Row>
        <Col md={2} className="timeline-container">
          <div className="timeline">
            <div className="timelineItemActive"></div>
            <div className="timelineItem"></div>
            <div className="timelineItem"></div>
          </div>
        </Col>
        <Col md={6}>
          <div className="content">
            <h1 className="heading">Welcome Screen</h1>
            <p className="description">
              Lorem ipsum dolor sit amet consectetur. Tempor dolor nisl nec in nulla rhoncus ut. Tempus morbi donec odio velit vestibulum egestas ultrices mattis. Feugiat donec sit enim lorem ut enim eget pharetra. Mi vestibulum gravida nam enim ac gravida consectetur vitae.
            </p>
            <Button variant="primary" className="nextButton" onClick={onNext}>Next</Button>
          </div>
        </Col>
        <Col md={4} className="image-container">
          <img src={onboardimages} alt="Illustration" className="image" />
        </Col>
      </Row>
    </Container>
  );
};

export default Step1;
