import moment from "moment";

export const API_BASE_URL = "https://gcs.tp-devserver.com/api/v1";
// export const API_BASE_URL = "http://192.168.1.114:3000/api/v1";
// export const API_BASE_URL = "http://localhost:3000/api/v1";
// export const API_BASE_URL = "http://192.168.1.47:3000/api/v1";


export const toCheckIsCityOrCountyExist = (cityNames, input) => {
  const lowercasedInput = input?.toLowerCase();
  for (const city of cityNames) {
    const cityName = city?.toLowerCase();
    if (lowercasedInput.includes(cityName)) {
      return city;
    }
  }
  return null;
};

export const removeCityOrCountyNameFromString = (str, cityName) => {
  const regex = new RegExp(`\\b${cityName}\\b`, "i");
  return str
    ?.replace(regex, "")
    ?.trim()
    ?.replace(/\s{2,}/g, " ");
};

export const formatDate = (input) => {
  const formats = [
    "MM/DD/YYYY",
    "M/D/YYYY",
    "ddd DD MMM",
    "MMMM D, YYYY",
    "MMM DD, YYYY h:mm A",
    "MMMM D, YYYY h:mm A",
    "MMMDD, YYYY hh:mm A",
  ];

  let parsedDate = null;
  for (const format of formats) {
    parsedDate = moment(input.replace(/\s+/, ""), format, true);
    if (parsedDate.isValid()) {
      return parsedDate.format("MM/DD/YYYY");
    }
  }
  const datePatterns = [
    /(\w+ \d{1,2}, \d{4} \d{1,2}:\d{2} (?:AM|PM))/, // Matches "May 01, 2024 05:00 PM"
    /(\d{1,2}\/\d{1,2}\/\d{4})/, // Matches "12/19/2023" or "8/8/2023"
    /(\d{1,2}\/\d{1,2}\/\d{2,4})/, // Matches "8/8/23" (less common)
    /(\w+ \d{1,2}, \d{4})/, // Matches "August 2, 2023"
  ];
  for (const pattern of datePatterns) {
    const match = input.match(pattern);
    if (match) {
      const extractedDate = match[1];
      parsedDate = moment(
        extractedDate,
        ["MMMM D, YYYY", "MM/DD/YYYY", "M/D/YYYY"],
        true
      );
      if (parsedDate.isValid()) {
        return parsedDate.format("MM/DD/YYYY");
      }
    }
  }
  return "Invalid Date";
};

export const formatTimeRange = (timeString) => {
  const timePattern = /(\d{1,2}:\d{2}\s?[APM]{2})/i;
  const match = timeString.match(timePattern);
  if (match) {
    const time = match[1].toUpperCase();
    const formattedTime = moment(time, "h:mm A").format("h:mm A");
    return formattedTime;
  } else {
    return "";
  }
};

export const firebaseConfig = () => {
  return {
    apiKey: "AIzaSyALwE2WI3CCSBIaw2A9dwXNaFR7_cXjo3w",
    authDomain: "legislative-tracker-104f8.firebaseapp.com",
    projectId: "legislative-tracker-104f8",
    storageBucket: "legislative-tracker-104f8.appspot.com",
    messagingSenderId: "1072522742080",
    appId: "1:1072522742080:web:87818694b8ef04a985d2a5",
    measurementId: "G-KX8K2KQCCS",
  };
};
