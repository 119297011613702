import { createSlice } from "@reduxjs/toolkit";
import {
    fetchingAllChatHistoryData,
    fetchingChatbotMessage,
    fetchingChatHistoryDataById,
    deleteAllChatHistoryData, // Import your delete thunk
} from "./chatbotThunk";

const initialState = {
    allChatHistory: {
        records: [],
        totalCount: 0,
        currentSize: 0,
        currentPage: 0,
    },
    selectedChat: [],
    isLoading: false,
    error: null,
};

const chatbotSlice = createSlice({
    name: "chatbot",
    initialState,
    reducers: {
        sendUserMessage: (state, action) => {
            state.selectedChat.push(action.payload);
        },
        clearSelectedChat: (state) => {
            state.selectedChat = [];
        },
        resetChatState: (state) => {
            // Reset all relevant chat state here
            state.selectedChat = [];
            state.chatHistory = [];
            // Reset any other chat-related state
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchingAllChatHistoryData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchingAllChatHistoryData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allChatHistory = {
                    records: action.payload.records,
                    totalCount: action.payload.totalCount,
                    currentSize: action.payload.currentSize,
                    currentPage: action.payload.currentPage,
                };
            })
            .addCase(fetchingAllChatHistoryData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || action.error.message;
                state.allChatHistory = {
                    records: [],
                    totalCount: 0,
                    currentSize: 0,
                    currentPage: 0,
                };
            });
        
        builder
            .addCase(fetchingChatHistoryDataById.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchingChatHistoryDataById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.selectedChat = [
                    { text: action.payload?.question, sender: 'user' }, 
                    { text: action.payload?.answer, sender: 'bot' },
                ];
            })
            .addCase(fetchingChatHistoryDataById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || action.error.message;
            });
        
        builder
            .addCase(fetchingChatbotMessage.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchingChatbotMessage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.selectedChat = [
                    ...state.selectedChat, 
                    { text: action.payload?.response, sender: "bot" },
                ];
            })
            .addCase(fetchingChatbotMessage.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || action.error.message;
            });

        // Handle deleting all chat history
        builder
            .addCase(deleteAllChatHistoryData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteAllChatHistoryData.fulfilled, (state) => {
                state.isLoading = false;
                state.allChatHistory = {
                    records: [],
                    totalCount: 0,
                    currentSize: 0,
                    currentPage: 0,
                };
            })
            .addCase(deleteAllChatHistoryData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || action.error.message;
            });
    },
});

export const { sendUserMessage, clearSelectedChat, resetChatState } = chatbotSlice.actions;
export default chatbotSlice.reducer;
