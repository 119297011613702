import { createAsyncThunk } from "@reduxjs/toolkit";
import CHATBOT from "../../../service/Chatbot/Chatbot";

// Fetch all chat history data
export const fetchingAllChatHistoryData = createAsyncThunk(
    "chatbot/fetchingAllChatHistoryData",
    async (_, { rejectWithValue }) => {
        try {
            const response = await CHATBOT.getAllChatHistoryData();
            return response.data.results;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Delete chat history data by ID and refresh the chat history
export const deletingChatHistoryDataById = createAsyncThunk(
    "chatbot/deletingChatHistoryDataById",
    async (id, { rejectWithValue, dispatch }) => {
        try {
            const response = await CHATBOT.deleteChatHistoryDataById(id);
            if (response?.data.code === 200 && response?.data.success) {
                // Fetch the updated chat history after deletion
                dispatch(fetchingAllChatHistoryData());
            }
            return response.data.results;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Fetch chat history data by ID
export const fetchingChatHistoryDataById = createAsyncThunk(
    "chatbot/fetchingChatHistoryDataById",
    async (id, { rejectWithValue }) => {
        try {
            const response = await CHATBOT.getChatHistoryDataById(id);
            return response.data.results;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Fetch chatbot message
export const fetchingChatbotMessage = createAsyncThunk(
    "chatbot/fetchingChatbotMessage",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await CHATBOT.getChatBotMessage(payload);
            return response.data.results;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteAllChatHistoryData = createAsyncThunk(
    "chatbot/deleteAllChatHistoryData",
    async (_, {rejectWithValue}) =>{
        try {
            const response = await CHATBOT.deleteallchathistory()
            return response.data.results
            
        } catch (error) {
            return rejectWithValue(error.response.data)
            
        }
    }
)
