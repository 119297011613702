import { createAsyncThunk } from "@reduxjs/toolkit";
import AdvanceFilter from "../../../service/AdvanceFilter/AdvanceFilter";

export const saveAdvanceFilter = createAsyncThunk(
  "advancefilters/filterHistory/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AdvanceFilter.addFilterHistory(data)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching council members");
    }
  }
);

export const getAllAdvanceFilters = createAsyncThunk(
  "advancefilters/getAllAdvanceFilters",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AdvanceFilter.fetchFilterHistory()
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching council members");
    }
  }
);

export const getallCouncilMembers = createAsyncThunk(
  "advancefilters/getallCouncilMembers",
  async ({ filters }, { rejectWithValue }) => {
    try {
      const response = await AdvanceFilter.fetchAllMembers(filters)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching council members");
    }
  }
);

export const getAllSearchAdvanceFilters = createAsyncThunk(
  "advancefilters/getAllSearchAdvanceFilters",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AdvanceFilter.fetchSearchFilterHistory(payload)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching council members");
    }
  }
);

export const deletingSearchAdvanceFilter = createAsyncThunk(
  "advancefilters/deletingSearchAdvanceFilter",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AdvanceFilter.deleteSearchFilterHistory(payload)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching council members");
    }
  }
);

export const fetchingCitiesByCounties = createAsyncThunk("advancefilters/fetchingCitiesByCounties", async (payload, { rejectWithValue }) => {
  try {
    const response = await AdvanceFilter.fetchCitiesByCountiesData(payload)
    return response.data
  } catch (error) {
    return rejectWithValue(error.response?.data ?? "Error fetch all cities")
  }
})

