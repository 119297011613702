import { createAsyncThunk } from "@reduxjs/toolkit";
import User from "../../../service/User/User";

export const registerUser = createAsyncThunk(
  "user/register",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.userRegister(payload);
      localStorage.setItem("auth", JSON.stringify(response.data.results));
      return response.data.results;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  "user/login",
  async (userdata, { rejectWithValue }) => {
    try {
      const response = await User.userLogin(userdata);
      localStorage.setItem("auth", JSON.stringify(response.data.results));
      return response.data.results;
    } catch (error) {      
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  "user/update",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await User.userUpdate(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserDetailsService = createAsyncThunk(
  "user/get-details",
  async (_, { rejectWithValue }) => {
    try {
      const response = await User.userDetails();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
