import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./header.css";
import usericon from "../../assets/Avatar.png";
import { useNotification } from "../../context/NotificationContext";
import Notification from "../notifications/Notification"; 
import gcslogo from '../../assets/gcs-legestative-logo.png'

const Header = () => {
  const { addNotification, clearAllNotifications } = useNotification();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user?.user);
  const { name, profile_picture } = userData || {};

  const [showNotification, setShowNotification] = useState(false); // State to control notification visibility
  const navigate = useNavigate();
  let isUserAuthenticated = JSON.parse(localStorage.getItem("auth"));

  

 

  const handleClearAll = () => {
    clearAllNotifications(() => setShowNotification(false)); // Clear notifications and close panel
  };

 

  return (
    <section>
      <div>
      <div className="header-container container">
      <nav className="navbar navbar-expand-lg bg-body-light navbar-header-section">
        {/* <div className="container"> */}
          <a className="navbar-brand" href="/">
            <img src={gcslogo} alt="GCS Logo" />
          </a>
          {/* Use d-flex to keep items aligned in mobile and desktop */}
          <div className="d-flex ms-auto align-items-center">
            <div className="navbar-nav me-auto">
              {!isUserAuthenticated && (
                <Link className="nav-link" to="/">
                  Login/SignUp
                </Link>
              )}
            </div>
            {/* Keep user icon always visible on both desktop and mobile */}
            {isUserAuthenticated?.token && (
              <div className="d-flex align-items-center">
                <Link to="/profile" className="user-icon circular--portrait">
                  <img
                    src={profile_picture ? profile_picture : usericon}
                    alt="User Profile"
                    width="35"
                    height="35"
                    className="img-fluid"
                  />
                </Link>
              </div>
            )}
          </div>
        {/* </div> */}
      </nav>
      </div>

      {/* Pass isVisible prop to Notification component */}
      <Notification isVisible={showNotification} handleClearAll={handleClearAll} />
    </div>
    </section>
  );
};

export default Header;