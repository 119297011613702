import { doFetch, REQUEST_METHODS } from "../fetcher";
import ADVANCE_FILTER_API_ENDPOINTS from "./AdvanceFilterAPIEndPoints";

export default {
    fetchFilterHistory: () => {
        return doFetch(
            `${ADVANCE_FILTER_API_ENDPOINTS.FETCH_FILTER_HISTORY}`,
            REQUEST_METHODS.GET
        );
    },
    addFilterHistory: (payload) => {
        return doFetch(
            `${ADVANCE_FILTER_API_ENDPOINTS.ADD_FILTER_HISTORY}`,
            REQUEST_METHODS.POST,
            payload
        );
    },
    fetchAllMembers: (payload) => {
        return doFetch(
            `${ADVANCE_FILTER_API_ENDPOINTS.FETCH_ALL_MEMBERS}/${JSON.stringify(payload)}`,
            REQUEST_METHODS.GET
        );
    },
    fetchSearchFilterHistory: (payload) => {
        const { pageSize = 2, page = 1, search = "" } = payload
        return doFetch(
            `${ADVANCE_FILTER_API_ENDPOINTS.FETCH_SEARCH_FILTER_HISTORY}${pageSize && page ? `?pageSize=${pageSize}&page=${page}` : ""}${search?.length ? `&search=${search}` : ""}`,
            REQUEST_METHODS.GET
        );
    },
    deleteSearchFilterHistory: (id) => doFetch(
        `${ADVANCE_FILTER_API_ENDPOINTS.DELETE_SEARCH_FILTER_HISTORY}/${id}`,
        REQUEST_METHODS.DELETE
    ),
    fetchCitiesByCountiesData: (payload) => {
        return doFetch(`${ADVANCE_FILTER_API_ENDPOINTS.FETCH_CITIES_BY_COUNTIES}`, REQUEST_METHODS.POST, payload)
    }
};