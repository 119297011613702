import { Navigate, Outlet } from "react-router-dom";
import Header from "../components/Navbar/Header";


const PrivateRoute = () => {
  const authData = localStorage.getItem("auth");

  if (!authData) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Header />
      <Outlet />
      
    </>
  );
};

export default PrivateRoute;
