import { createAsyncThunk } from "@reduxjs/toolkit";
import Data from "../../../service/Data/Data";

export const getallsearchresult = createAsyncThunk(
    "data/getallsearchresult",
    async (payload) => {
        const response = await Data.fetchAllSearchResult(payload)
        return response.data;
    }
);

export const getAllCities = createAsyncThunk(
    "data/getAllCities",
    async () => {
        const response = await Data.fetchAllCities()
        return response.data;
    }
);

export const getCitiesByCounty = createAsyncThunk(
    "data/getCitiesByCounty",
    async (id) => {
        const response = await Data.fetchCitiesByCounty(id)
        return response.data;
    }
);

export const getAllCounties = createAsyncThunk(
    "data/getAllCounties",
    async () => {
        const response = await Data.fetchAllCounties()
        return response.data;
    }
);