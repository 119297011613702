import { doFetch, REQUEST_METHODS } from "../fetcher";
import DATA_API_ENDPOINTS from "./DataAPIEndPoints";

export default {
    fetchAllSearchResult: (payload) => {
        const { pageSize = 12, page = 1, data } = payload;
        return doFetch(
            `${DATA_API_ENDPOINTS.ALL_SEARCH_RESULT}?pageSize=${pageSize}&page=${page}`,
            REQUEST_METHODS.POST,
            data
        );
    },
    fetchAllCities: () => {
        return doFetch(`${DATA_API_ENDPOINTS.ALL_CITIES}`, REQUEST_METHODS.GET);
    },
    fetchAllCounties: () => {
        return doFetch(`${DATA_API_ENDPOINTS.ALL_COUNTIES}`, REQUEST_METHODS.GET);
    },
    fetchCitiesByCounty: (id) => {
        return doFetch(`${DATA_API_ENDPOINTS.FETCH_CITIES_BY_COUNTY}/${id}`, REQUEST_METHODS.GET);
    }
};