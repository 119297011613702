import axios from 'axios';
import { API_BASE_URL } from './utils/constant';

const getToken = () => {
  const userDetails = JSON.parse(localStorage.getItem('auth'));
  return userDetails ? userDetails.token : null;
};

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${getToken()}`, 
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log('Unauthorized: Access is denied due to invalid credentials.');
      localStorage.removeItem("auth");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
