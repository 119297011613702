import { createSlice } from "@reduxjs/toolkit";
import { fetchData } from "./filterThunk";

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    selectedOptions: [],
    data: [],
    filteredData: [],
    error: null,
    isLoading: false,
    status: 'idle',
  },
  reducers: {
    toggleOption: (state, action) => {
      const option = action.payload;

      // Log the type and check if selectedOptions is an array
      console.log('selectedOptions type:', typeof state.selectedOptions);  // This will return 'object' for arrays
      console.log('Is selectedOptions an array?', Array.isArray(state.selectedOptions));

      // Ensure selectedOptions is an array before using .filter() or .push()
      if (!Array.isArray(state.selectedOptions)) {
        console.error('selectedOptions is not an array, resetting to an empty array.');
        state.selectedOptions = [];
      }

      // Toggle the option in selectedOptions array
      if (state.selectedOptions.includes(option)) {
        state.selectedOptions = state.selectedOptions.filter(opt => opt !== option);
      } else {
        state.selectedOptions.push(option);
      }

      console.log('Updated selectedOptions:', state.selectedOptions);
    },
    setSelectedOptions: (state, action) => {
      // Log the payload and its type before setting selectedOptions
      console.log('Payload received in setSelectedOptions:', action.payload);
      console.log('Is payload an array?', Array.isArray(action.payload));

      if (Array.isArray(action.payload)) {
        state.selectedOptions = action.payload;
      } else {
        console.error('Payload is not an array, resetting selectedOptions to an empty array.');
        state.selectedOptions = [];
      }
    },
    clearSelectedCategories: (state) => {
      console.log('Clearing selected categories.');
      state.selectedOptions = [];
    }
  }
  ,
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.filteredData = action.payload;
        state.status = 'succeeded';
        state.isLoading = false;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.isLoading = false;
      });
  }
});

export const { toggleOption, clearSelectedCategories, setSelectedOptions } = filterSlice.actions;
export default filterSlice.reducer;