import { API_BASE_URL } from "../../../utils/constant";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../axiosInstance";

export const fetchData = createAsyncThunk("filter/fetchData", async (selectedOptions, { rejectWithValue }) => {
  try {
    const endpoints = selectedOptions.map(option => {
      switch (option) {
        case 'Councilmember':
          return `${API_BASE_URL}/member/fetchAllMembers`;
        case 'municipal':
          return `${API_BASE_URL}/municipal`;
        case 'meetings':
          return `${API_BASE_URL}/meeting/fetchAllMeetings`;
        default:
          return null;
      }
    }).filter(Boolean);

    // Log the selected options and generated endpoints
    console.log('Selected options:', selectedOptions);
    console.log('Generated endpoints:', endpoints);

    if (endpoints.length === 0) {
      return [];  // No endpoints to call
    }

    const requests = endpoints.map(endpoint => axiosInstance.get(endpoint));
    const responses = await Promise.all(requests);

    // Ensure each response contains an array and handle unexpected structures
    return responses.flatMap(response => Array.isArray(response.data) ? response.data : []);
  } catch (error) {
    console.error('Error fetching data:', error);
    return rejectWithValue(error.message || 'Error fetching data');
  }
});
