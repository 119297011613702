import { createSlice } from "@reduxjs/toolkit";
import { fetchUserDetailsService, loginUser, registerUser, updateUserDetails } from "./userThunk";

const initialState = {
    user: null,
    token: null,
    isLoading: false,
    error: null,
};

// Check local storage for auth details
// const authFromLocalStorage = JSON.parse(localStorage.getItem("auth"));
// if (authFromLocalStorage) {
//   initialState.user = authFromLocalStorage.userDetail;
//   initialState.token = authFromLocalStorage.token;
// }

// User slice
const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem("auth");
            state.user = null;
            state.token = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // Register user
            .addCase(registerUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload.userDetail;
                state.token = action.payload.token;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || action.error.message;
                console.error("Register rejected:", state.error);
            });
        // Login user
        builder.addCase(loginUser.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload.userDetail;
                state.token = action.payload.token;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || action.error.message;
            });
        // Update user details
        builder.addCase(updateUserDetails.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        }).addCase(updateUserDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.user = action.payload.results.userDetail;
        })
            .addCase(updateUserDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || action.error.message;
            });
        // Fetch user details
        builder.addCase(fetchUserDetailsService.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        }).addCase(fetchUserDetailsService.fulfilled, (state, action) => {
            state.isLoading = false;
            state.user = action.payload.data;
        }).addCase(fetchUserDetailsService.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload || action.error.message;
            if (action.payload?.error === "Unauthorized") {
                localStorage.removeItem("auth");
            }
        });
    },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;