import React, { createContext, useState } from 'react';

import LegistativeUpdates from "../assets/onboarding/legistative updates.jpg";
import Billtracking from "../assets/onboarding/bill treacking.jpg";
import ComiteeReports from "../assets/onboarding/cometee reports.jpg";
import LegistativeCalendar from "../assets/onboarding/legistative calendar.jpg";
import publicHearing from "../assets/onboarding/public hearing.jpg";
import votingrecord from "../assets/onboarding/voting records.jpg";
import policyanalysis from "../assets/onboarding/policy analysis.jpg";
import legistativehistory from "../assets/onboarding/legistative history.jpg";
import regulatorychanges from "../assets/onboarding/regularity changes.jpg";
import budgetregulation from "../assets/onboarding/budgets legistations.png";
import publiccomments from "../assets/onboarding/public comments.jpg";
import lobbyingactivities from "../assets/onboarding/lobbyiing activities.png";
import legistatorprofiles from "../assets/onboarding/legistator profiles.jpg";
import amendmenttracking from "../assets/onboarding/amendant tracking.png";
import legistativesummary from "../assets/onboarding/legistative summary.jpg";
import fiscal from "../assets/onboarding/fiscal.png";
import legistativenews from "../assets/onboarding/legistative news.jpg";
import electionresults from "../assets/onboarding/election results.png";
import legistativeglossary from "../assets/onboarding/legistative glossy.jpg";
import judicialreviews from "../assets/onboarding/judicial reviews.png";
import background from "../assets/step2.png";

// Create Context
export const OnboardingContext = createContext();

// Create a provider component
export const OnboardingProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedCounties, setSelectedCounties] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  // Define topics array
  const topics = [
    { id: 1, name: "Legistative Updates", image: LegistativeUpdates },
    { id: 2, name: "Bill Tracking", image: Billtracking },
    { id: 3, name: "Commitee Reports", image: ComiteeReports },
    { id: 4, name: "Legistative Calendar", image: LegistativeCalendar },
    { id: 5, name: "Public Hearing", image: publicHearing },
    { id: 6, name: "Voting Record", image: votingrecord },
    { id: 7, name: "Policy Analysis", image: policyanalysis },
    { id: 8, name: "Legistative History", image: legistativehistory },
    { id: 9, name: "Regulatory Changes", image: regulatorychanges },
    { id: 10, name: "Budget Legistation", image: budgetregulation },
    { id: 11, name: "Public Comments", image: publiccomments },
    { id: 12, name: "Lobbying Activities", image: lobbyingactivities },
    { id: 13, name: "Legistator Profiles", image: legistatorprofiles },
    { id: 14, name: "Legistative Summaries", image: legistativesummary },
    { id: 15, name: "Amendment Tracking", image: amendmenttracking },
    { id: 16, name: "Fiscal Impact Statements", image: fiscal },
    { id: 17, name: "Legistative news", image: legistativenews },
    { id: 18, name: "Election Results", image: electionresults },
    { id: 19, name: "Legistative Glossary", image: legistativeglossary },
    { id: 20, name: "Judicial Reviews", image: judicialreviews }
  ];

  // Define steps data
  const steps = [
    { element: '#step1', intro: 'This is step 1' },
    { element: '#step2', intro: 'This is step 2' },
    { element: '#step3', intro: 'This is step 3' },
  ];

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => (prevStep > 0 ? prevStep - 1 : 0));
  };

  const toggleTopicSelection = (id) => {
    setSelectedTopics((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((topicId) => topicId !== id)
        : [...prevSelected, id]
    );
  };

  // const selectAllTopics = (allSelected) => {
  //   console.log("allSelected ---> ", allSelected);
  //   if (allSelected) {
  const selectAllTopics = () => {
    if (selectedTopics.length === topics.length) {
      setSelectedTopics([]);
    } else {
      const allTopicIds = topics.map(topic => topic.id);
      setSelectedTopics(allTopicIds);
    }
  };

  const updateSelectedCounties = (counties) => {
    setSelectedCounties(counties);
  };

  const updateSelectedCities = (cities) => {
    setSelectedCities(cities);
  };

  return (
    <OnboardingContext.Provider value={{
      currentStep, steps, selectedTopics, setSelectedTopics, toggleTopicSelection,
      selectAllTopics, selectedCounties, updateSelectedCounties,
      selectedCities, updateSelectedCities, handleNext, handlePrevious, topics
    }}>
      {children}
    </OnboardingContext.Provider>
  );
};
