import { createSlice } from "@reduxjs/toolkit";
import { getAllCities, getAllCounties, getallsearchresult } from "./dataThunk";

const dataSlice = createSlice({
    name: "data",
    initialState: {
        searchResults: {},
        walnutcreekResults: null,
        citycouncilResults: null,
        meetingsResults: null,
        isCitiesLoading: false,
        cities: [],
        isCountiesLoading: false,
        counties: [],
        status: "idle",
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getallsearchresult.pending, (state) => {
            state.status = "loading";
            state.isLoading = true;
        });
        builder.addCase(getallsearchresult.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.searchResults = action.payload.results;
            state.isLoading = false;
        });
        builder.addCase(getallsearchresult.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            state.isLoading = false;
        });
        builder.addCase(getAllCities.pending, (state) => {
            state.isCitiesLoading = true;
        }).addCase(getAllCities.fulfilled, (state, action) => {
            state.isCitiesLoading = false;
            state.cities = action.payload?.results;
        }).addCase(getAllCities.rejected, (state, action) => {
            state.isCitiesLoading = false;
            state.cities = [];
            state.error = action.error.message;
        });
        builder.addCase(getAllCounties.pending, (state) => {
            state.isCountiesLoading = true;
        }).addCase(getAllCounties.fulfilled, (state, action) => {
            state.isCountiesLoading = false;
            state.counties = action.payload?.results;
        }).addCase(getAllCounties.rejected, (state, action) => {
            state.isCountiesLoading = false;
            state.counties = [];
            state.error = action.error.message;
        });
    },
});

export const { } = dataSlice.actions;
export default dataSlice.reducer;